.container {
  background-color: #f7f7fa;
  height: 100vh;
  font-family: PingFangSC-Regular;
}
.container .content {
  background-color: #f7f7fa;
  padding-bottom: 1rem;
}
.container .head_right {
  color: #333333;
  font-size: 14px;
}
.nav {
  margin-top: 0.2rem;
  border-top-left-radius: 0.32rem;
  border-top-right-radius: 0.32rem;
  background-color: #ffffff;
  font-family: PingFang SC;
  padding: 0.15rem 0;
}
.nav .nav_item1 {
  font-size: 14px;
  padding: 0.15rem 33px;
  color: #8f9eab;
}
.nav .nav_item1 .item {
  margin-left: 30px;
}
.nav .nav_item1 .item:first-child {
  margin-left: 0;
}
.nav .nav_item2 {
  font-size: 14px;
  padding: 0.15rem 33px;
  color: #8f9eab;
  display: flex;
  justify-content: space-between;
}
.nav .nav_item2 .item {
  text-align: center;
}
.nav .nav_item2 .item:first-child {
  margin-left: 0;
}
.nav .nav_item3 {
  font-size: 14px;
  padding: 0.15rem 33px;
  color: #8f9eab;
}
.nav .nav_item3 .item {
  margin-left: 30px;
  text-align: center;
}
.nav .nav_item3 .item:first-child {
  margin-left: 0;
}
.nav .nav_item4 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  padding: 0.15rem 34px;
  color: #8f9eab;
}
.nav .nav_item4 .item4_1 {
  border-radius: 4px;
  background-color: #f7f7fa;
  padding: 6px 5px 6px 5px;
  text-align: center;
}
.nav .nav_item4 .item4_2 {
  border-radius: 4px;
  background-color: #f7f7fa;
  padding: 6px 5px 6px 5px;
  text-align: center;
}
.nav .nav_item4 .item4_3 {
  border-radius: 4px;
  background-color: #f7f7fa;
  padding: 6px 5px 6px 5px;
  text-align: center;
}
.nav .nav_item4 .active1 {
  color: #333;
  font-weight: 600;
}
.nav .active {
  color: #333333;
  font-size: 14px;
  font-weight: 600;
}
.content .teacher_list {
  background-color: #ffffff;
  margin-top: 10px;
  font-family: PingFang SC;
  border-top-left-radius: 0.32rem;
  border-top-right-radius: 0.32rem;
  display: flex;
  justify-content: space-between;
  padding: 0.12rem 0;
}
.content .teacher_list .msg_left {
  margin: 10px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.content .teacher_list .msg_left .img {
  width: 1.2rem;
  height: 1.2rem;
}
.content .teacher_list .msg_left .img img {
  border-radius: 0.5rem;
  width: 100%;
  height: 100%;
}
.content .teacher_list .msg_left .name {
  font-size: 0.28rem;
  font-weight: 500;
  color: #333333;
  text-align: center;
}
.content .teacher_list .msg_left .VIP {
  display: flex;
  align-items: center;
}
.content .teacher_list .msg_left .VIP .img_vip img {
  width: 0.252399rem;
  height: 0.2218rem;
}
.content .teacher_list .msg_left .VIP .vip_number {
  font-family: 'haose';
  margin-left: 0.05rem;
  font-size: 0.28rem;
  color: #2E62FF;
}
.content .teacher_list .msg_left .bili {
  margin-top: 0.1rem;
  background-color: rgba(46, 98, 255, 0.09);
  padding: 0.08rem 0.28rem 0.06rem 0.28rem;
  border-radius: 0.08rem;
  font-size: 0.2rem;
  transform: scale(0.91);
  color: #2E62FF;
  text-align: center;
  font-weight: 600;
}
.content .teacher_list .msg_right {
  margin-top: 0.6rem;
}
.content .teacher_list .msg_right .top {
  display: flex;
  align-items: center;
}
.content .teacher_list .msg_right .top .top_left {
  text-align: left;
}
.content .teacher_list .msg_right .top .top_right {
  margin-left: 0.58rem;
  text-align: left;
}
.content .teacher_list .msg_right .top .txt {
  color: #6f7a8b;
  font-size: 0.24rem;
}
.content .teacher_list .msg_right .top .left_num {
  color: #333333;
  font-size: 0.32rem;
}
.content .teacher_list .msg_right .top .right_num {
  color: #32bf88;
  font-size: 0.32rem;
}
.content .teacher_list .msg_right .bottom {
  margin-top: 0.6rem;
  display: flex;
  align-items: center;
}
.content .teacher_list .msg_right .bottom .top_left {
  text-align: left;
}
.content .teacher_list .msg_right .bottom .top_right {
  margin-left: 0.2rem;
  text-align: left;
}
.content .teacher_list .msg_right .bottom .txt {
  color: #6f7a8b;
  font-size: 0.24rem;
}
.content .teacher_list .msg_right .bottom .left_num {
  color: #333333;
  font-size: 0.32rem;
  font-weight: 600;
}
.content .teacher_list .msg_right .bottom .right_num {
  color: #333333;
  font-size: 0.32rem;
  font-weight: 600;
}
.content .teacher_list .msg_right1 {
  margin-top: 1.04rem;
  margin-right: 0.72rem;
}
.content .teacher_list .msg_right1 .top {
  display: flex;
  align-items: center;
}
.content .teacher_list .msg_right1 .top .top_left {
  text-align: left;
}
.content .teacher_list .msg_right1 .top .top_right {
  margin-left: 0.58rem;
  text-align: left;
}
.content .teacher_list .msg_right1 .top .txt {
  text-align: left;
  margin-left: -0.09rem;
  color: #666666;
  font-size: 0.24rem;
  transform: scale(0.83);
}
.content .teacher_list .msg_right1 .top .left_num {
  color: #5998fc;
  font-size: 0.3rem;
}
.content .teacher_list .msg_right1 .top .right_num {
  color: #d98181;
  font-size: 0.3rem;
}
.content .teacher_list .msg_right1 .bottom {
  margin-top: 0.16rem;
  display: flex;
  align-items: center;
}
.content .teacher_list .msg_right1 .bottom .top_left {
  text-align: left;
}
.content .teacher_list .msg_right1 .bottom .top_right {
  margin-left: 0.8rem;
  text-align: left;
}
.content .teacher_list .msg_right1 .bottom .txt {
  text-align: left;
  margin-left: -0.09rem;
  color: #666666;
  font-size: 0.24rem;
  transform: scale(0.83);
}
.content .teacher_list .msg_right1 .bottom .txt1 {
  margin-top: 0.15rem;
  text-align: left;
  color: #666666;
  font-size: 0.24rem;
}
.content .teacher_list .msg_right1 .bottom .left_num {
  color: #5998fc;
  font-size: 0.3rem;
}
.content .teacher_list .msg_right1 .bottom .right_num {
  color: #d98181;
  font-size: 0.3rem;
}
